<template>


  <div class="mt-4">
    <div v-if="countdownActive && countdown > 0" class="countdown-container" :class="{ 'red-background': countdownActive }">
      <h1 class="countdown-text">Self destruction in {{ countdown }}...</h1>
    </div>
    <div v-else class="explosion-container" v-show="explosionActive">
      <div class="explosion"></div>
    </div>

    <h1 v-if="team" class="primary--text mx-3" v-text="team.t_name"></h1>
    <h2 v-if="team && team.t_gamla_rallyt" class="primary--text mx-3"><i>"Hur svårt kan de egentligen göra det för oss?"</i> <br> Tobias Rallypuben 23</h2>
    <h2>Körtid: {{ this.raceTime ? this.raceTime : "00:00:00" }}</h2>
    <h3 v-if="team">Startnummer #<span v-text="team.t_start_position"></span></h3>
    <h4>Bana "<span v-text="courseName"></span>"</h4>
    <div class="text-center" v-if="team">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="400"
          :overlay-color='popUpData ? popUpData.color: ""'
          overlay-opacity="1" 
        >
          <v-card>
            <v-card-title class="text-h5" style="word-break: break-word">
              {{ popUpData ? popUpData.title : "" }}
            </v-card-title>
            <v-img
                  :src="popUpData ? popUpData.src: ''"
                  class="align-start"
                  height="375px"
                  cover
                >
              </v-img>
            <v-card-text class="mt-2">{{ popUpData ? popUpData.subtitle : "" }}</v-card-text>
            <v-card-actions class="mt-n5">
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                target="_blank"
                :href="popUpData ? popUpData.yesLink : ''"
                @click="dialog = false"
              >
                Ja
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="popupNej"
              >
                Nej
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div> 
    <v-container v-if="team" class="mt-4" >
      <v-row no-gutters v-if="team.t_gamla_rallyt">
          <v-col 
            v-for="card in gamlaRallyNavdata"
              :key="card.title"
              cols="12"
              lg="4"
              no-gutters
            >
            <v-card 
            :to="card.route" 
            class="rounded-xl my-n16"
            :class="getRandomClass()"
          >
              <v-img
                  :src="card.src"
                  class="align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="300px"
                  cover
                >
                </v-img>
                <v-card-title v-text="card.title"></v-card-title>
                <v-card-subtitle class="text-start" v-text="card.subtitle" ></v-card-subtitle>
              </v-card>
          </v-col>
      </v-row>
      <v-row dense v-else>
          <v-col
              v-for="card in navdata"
              :key="card.title"
              cols="12"
              lg="4"
            >
              <v-card :to="card.route" class="rounded-xl my-2 mx-2">
                <v-img
                  :src="card.src"
                  class="align-top"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="300px"
                  cover
                >
                </v-img>
                <v-card-title v-text="card.title"></v-card-title>
                <v-card-subtitle class="text-start" v-text="card.subtitle" ></v-card-subtitle>
              </v-card>
            </v-col>
      </v-row>
    </v-container>
</div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'DashboardHome',
  data: () => ({
    countdown: 5,
    countdownActive: false,
    explosionActive: false,
    countdownCompleted: false,
    navdata: [
      {
        src: require('@/assets/dashboard/stationer-dashboard.jpg'),
        title: "Stationer",
        subtitle: "Lös ReBuS fantastiska rebusar och ta en hjälprebus eller två (eller tre), eller kanske ett facit.",
        route: "/dashboard/stations",
      },
      {
        src: require('@/assets/dashboard/uppdrag-dashboard.jpg'),
        title: "Uppdrag",
        subtitle: "Utför legendariska uppdrag i HÄFTigs häftiga häfte och skicka in svaren här!",
        route: "/dashboard/missions",
      },
      {
        src: require('@/assets/dashboard/haftig-dashboard.png'),
        title: "HÄFTiga platser",
        subtitle: "Besök Uppsalas HÄFTigaste platser och skicka in era bildbevis",
        route: "/dashboard/haftig",
      },
      {
        src: require('@/assets/dashboard/tricky-dashboard.jpg'),
        title: "Kluringar",
        subtitle: "Lös svåra kluringar i HÄFTigs häftiga häfte och skicka in svaren här",
        route: "/dashboard/tricky",
      },
      {
        src: require('@/assets/dashboard/splek-dashboard.jpg'),
        title: "Bilunderhållning",
        subtitle: "Lyssna på SpleKs Fordonsfestligheter, lös hennes svåra kluringar i radioinslagen och skicka in svaren här",
        route: "/dashboard/splek",
      },
      {
        src: require('@/assets/dashboard/lunch-dashboard.jpg'),
        title: "Lunchstation",
        subtitle: "Checka ut efter att ni njutit av en mumsig lunch tillsammans med MumS!",
        route: "/dashboard/lunch",
      },
      {
        src: require('@/assets/dashboard/contact-dashboard.jpeg'),
        title: "Nö(r)dkontakter",
        subtitle: "Har något inte gått enligt planerna? Kontakta Nö(r)dkontakterna!",
        route: "/dashboard/contact",
      }
    ],
    gamlaRallyNavdata: [
    {
        src: require('@/assets/dashboard/stationer-dashboard.jpg'),
        title: "Stationer",
        subtitle: "Lös ReBuS fantastiska rebusar och ta en hjälprebus eller två (eller tre), eller kanske ett facit.",
        route: "/dashboard/contact",
        rotate: 290
      },
      {
        src: require('@/assets/dashboard/uppdrag-dashboard.jpg'),
        title: "Uppdrag",
        subtitle: "Utför legendariska uppdrag i HÄFTigs häftiga häfte och skicka in svaren här!",
        route: "/dashboard/haftig",
        rotate: 130
      },
      {
        src: require('@/assets/dashboard/haftig-oldRally.jpg'),
        title: "HÄFTiga platser",
        subtitle: "Besök Uppsalas HÄFTigaste platser och skicka in era bildbevis",
        route: "/dashboard/stations",
        rotate: 240
      },
      {
        src: require('@/assets/dashboard/tricky-dashboard.jpg'),
        title: "Kluringar",
        subtitle: "Lös svåra kluringar i HÄFTigs häftiga häfte och skicka in svaren här",
        route: "/dashboard/splek",
        rotate: 120
      },
      {
        src: require('@/assets/dashboard/splek-dashboard.jpg'),
        title: "Bilunderhållning",
        subtitle: "Lyssna på SpleKs Fordonsfestligheter, lös hennes svåra kluringar i radioinslagen och skicka in svaren här",
        route: "/dashboard/tricky",
        rotate: 190
      },
      {
        src: require('@/assets/dashboard/lunch-dashboard.jpg'),
        title: "Lunchstation",
        subtitle: "Checka ut efter att ni njutit av en mumsig lunch tillsammans med MumS!",
        route: "/dashboard/missions",
        rotate: 130
      },
      {
        src: require('@/assets/dashboard/contact-dashboard.jpeg'),
        title: "Nö(r)dkontakter",
        subtitle: "Har något inte gått enligt planerna? Kontakta för fan inte oss",
        route: "/dashboard/lunch",
        rotate: 280
      }
    ],
    gamlaRallyPopups: [
      {
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        title: "Sexy and single peppa:kakor in your area!11!!1",
        subtitle: "Prova sexiga peppa:kakor idag! Men var försiktig - de är heta!",
        yesLink: "https://www.youtube.com/watch?v=Ik0aTNuiPTI",
        color: "red"
      },
      {
        title: "SpleK wants to know your location",
        subtitle: "Varför? Åh.. det ska du inte oroa dig över",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://www.youtube.com/watch?v=ht3qpS_k7No",
        color: "green"
      },
      {
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        title: "Brukar du också hamna här ofta?",
        subtitle: "Helt rätt, fortsätt så!",
        yesLink: "https://www.youtube.com/watch?v=qkdYC8L4Fn0",
        color: "yellow"
      },
      {
        title: "Join the revolution today!",
        subtitle: "Gå med i den stora revolutionen och bli en del av något större!",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://www.youtube.com/watch?v=U06jlgpMtQs",
        color: "red"
      },
      {
        title: "Vill DU ha gratis tidsavdrag?",
        subtitle: "Boten Anna bjuder er på gratis tidsavdrag, bara för denna gång",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/natsak-botenAnna.jpg',
        yesLink: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        color: "blue"
      },
      {
        title: "ReBuS är inte imponerad över storleken...",
        subtitle: "Det är vetenskapligt bevisat att pepparkakor försämrar din prestation. Ät inte pepparkakor!",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://storage.googleapis.com/rally-23/rally22/pingis.mp4",
        color: "orange"
      },
      {
        title: "Så går du ned i vikt på 3 dagar!",
        subtitle: "Följ MumS legendariska guide och gå ned i vikt snabbare än ni kommer i mål på rallyt",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://youtu.be/QnrTiuqSLGQ?si=aWqeq2NDrFdaFFO2&t=3",
        color: "green"
      },
      {
        title: "Fysisk kontakt utan monitär transaktion!?!?!?",
        subtitle: "Nu är det omöjliga möjligt!",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://www.youtube.com/watch?v=TTJxqIokUUM",
        color: "purple"
      },
      {
        title: "OLVI: Världens godaste öl",
        subtitle: "ölölölölölölölölölölölölöl",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://www.youtube.com/watch?v=S41cyoU-YWE",
        color: "green"
      },
      {
        title: "HAR DU SETT DENNA MAN?",
        subtitle: "Den farliga individ har flytt från en anstalt ägd av Hemsö",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://www.facebook.com/groups/308239998522044/",
        color: "blue",
      },
      {
        title: "Nigerian princes wants to send you money and pepparkakor!",
        subtitle: "All he needs is your bank account number and social security number",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://www.youtube.com/shorts/ovaxzavU6s0",
        color: "green"
      },
      {
        title: "Extrapris på rallytjack!!!",
        subtitle: "Legendarisk tsatsiki!! Swisha till 0702290543 och bidra till efterTB",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://www.youtube.com/watch?v=94qUw0raoN4",
        color: "blue"
      },
      {
        title: "Fattiga rallykåiter behöver din hjälp!",
        subtitle: "Bidra till en legendarisk efterTB",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://www.youtube.com/watch?v=CRQY8a9BTkE",
        color: "orange"
      },
      {
        title: "Todays rally is sponsored by Raid Shadow Legends",
        subtitle: "Currently almost 10 million users have joined Raid over the last six months, and it's one of the most impressive games in its class with detailed models, environments and smooth 60 frames per second animations!",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://www.youtube.com/watch?v=gmwbHdJLadE",
        color: "yellow"
      },
      {
        title: "Vill du också se ut såhär?",
        subtitle: "Käka pepparkakor och du kommer se ut som en riktig rallykåit!",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://www.youtube.com/watch?v=NLpKMidqy2M&list=PLQ1vMmtAoD5STMPwVdEbs5hGBylS8y5x6",
        color: "green"
      },
      {
        title: "Det är aldrig för sent att söka hjälp",
        subtitle: "Personer som strular med anmälan och betalning kan ha värre konsekvenser än du tror...",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://www.youtube.com/watch?v=s5TB5aGuxP0",
        color: "red"
      },
      {
        title: "Storebror ser allt..",
        subtitle: ".. och hör allt",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://youtu.be/5BEUykdqTq0?si=vcz_tePQMWvnf9EC&t=4",
        color: "blue"
      },
      {
        title: "4 av 5 rallykåiter rekommenderar",
        subtitle: "Hålen räcker inte dock till alla...",
        src: 'https://storage.googleapis.com/rally-24/rally23/rally23-popups/robban-flagga.jpg',
        yesLink: "https://storage.googleapis.com/rally-23/rally22/mys.png",
        color: "black"
      }
    ],
    animationClasses: ['move-up-down', 'move-side', 'spin'],
    popUpData: null,
    "team": null,
    "courseName": null,
    timer: null,
    "raceTime": null,
    dialog: false
  }),
  methods: {
    ...mapActions(['getTeam']),
    async getUserTeam() {
      const team = await this.getTeam()
      this.team = team
      this.updateRaceTime()
      if (team.t_gamla_rallyt && !this.countdownCompleted) {
        this.courseName = "Eeeee lycka till"
        this.startCountdown()
        // Show popup
        
        this.dialog = true
        this.popUpData = this.gamlaRallyPopups[Math.floor(Math.random() * this.gamlaRallyPopups.length)]
        
      }
      else if(team.t_start_position % 2 == 0) {
        this.courseName = process.env.VUE_APP_EVEN_COURSE_NAME
      } else {
        this.courseName = process.env.VUE_APP_ODD_COURSE_NAME
      }
    },
    popupNej() {
      confirm(`Är du säker på att du vill säga nej?`)
      confirm(`Är du heeelt säker?`)
      confirm(`Jobbigt när det man själv skapat biter en i röven :(`)
      confirm(`Karmas a bitch, i should have known better`)
      this.dialog = false
      this.popUpData = null
    },
    updateRaceTime() {
      const startTime = this.team.t_ts_start ? new Date(this.team.t_ts_start) : new Date()
      const endTime = this.team.t_ts_finish ? new Date(this.team.t_ts_finish) : new Date()
      const lunchStartTime = this.team.t_ts_lunch_in ? new Date(this.team.t_ts_lunch_in) : new Date()
      const lunchEndTime = this.team.t_ts_lunch_out ? new Date(this.team.t_ts_lunch_out) : new Date()
      let raceTime = endTime - startTime - (lunchEndTime - lunchStartTime)
      if(this.team.t_gamla_rallyt){
        raceTime = raceTime * 4
      }
      const raceHours = Math.floor(raceTime / 3600000)
      const raceMinutes = Math.floor((raceTime - (raceHours * 3600000)) / 60000)
      const raceSeconds = Math.floor((raceTime - (raceHours * 3600000) - (raceMinutes * 60000)) / 1000)
      
      // Convert to 00:00:00 format
      const raceHoursStr = raceHours < 10 ? "0" + raceHours : raceHours
      const raceMinutesStr = raceMinutes < 10 ? "0" + raceMinutes : raceMinutes
      const raceSecondsStr = raceSeconds < 10 ? "0" + raceSeconds : raceSeconds
      this.raceTime = raceHoursStr + ":" + raceMinutesStr + ":" + raceSecondsStr
    },
    getRandomClass() {
      const randomIndex = Math.floor(Math.random() * this.animationClasses.length);
      return this.animationClasses[randomIndex];
    },
    startCountdown() {
      this.countdownActive = true;
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(interval);
          this.countdownCompleted = true;
          this.triggerExplosion();
        }
      }, 1000);
    },
    triggerExplosion() {
      this.explosionActive = true;
      setTimeout(() => {
        this.explosionActive = false;
        this.countdownActive = false;
      }, 6000); // Adjust explosion duration as needed
    }
  },
  mounted() {
    this.getUserTeam()
    
    // Update race timer every second
    this.timer = setInterval(() => {
      this.updateRaceTime()
    }, 250)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style>


@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-200px);
  }
}

@keyframes moveSide {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.move-up-down {
  animation: moveUpDown 1s ease-in-out infinite;
}

.move-side {
  animation: moveSide 1s ease-in-out infinite;
}

.spin {
  animation: spin 2s linear infinite;
}


.countdown-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: background-color 0.5s;
}

.red-background {
  background-color: red;
}

.countdown-text {
  font-size: 5rem;
  color: white;
  text-align: center;
  animation: blink 1s steps(2, start) infinite;
}

.explosion-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: 10000;
}

.explosion {
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: orange;
  animation: explode 2s forwards;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

@keyframes explode {
  0% {
    width: 0;
    height: 0;
  }
  50% {
    width: 50vw;
    height: 50vw;
  }
  100% {
    width: 100vw;
    height: 100vw;
    opacity: 0;
  }
}

</style>